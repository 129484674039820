<template>
  <v-row class="background" no-gutters>
    <v-col cols="12" class="pa-0">
      <v-img
        class="pt-0"
        :src="quizData.upload_image"
        max-width="100vw"
        max-height=""
      />
      <!-- style="display: block; margin-left: auto; margin-right: auto" -->
    </v-col>
    <v-col class="mt-6 ml-10 pa-0 fn22">
      {{ quizData.title }}
      <p style="font-size: 14px; font-weight: 200">
        {{ quizData.description }}
      </p>
      <p style="font-size: 14px; font-weight: 200">
        point: {{ quizData.point }}
      </p>
    </v-col>
    <v-col cols="12" class="ma-0 pa-0"><v-divider /></v-col>
    <v-row
      v-for="(i, index) in quizData.question_list"
      :key="index"
      class="px-6"
      no-gutters
    >
      <v-col cols="12" class="mt-4">
        <span style="color: #1a347f">{{ i.title }}</span>
      </v-col>
      <v-row v-if="i.question_type == 'SHORT'" dense>
        <v-col
          v-for="(j, index) in i.option"
          :key="index"
          cols="12"
          class="mt-2"
        >
          <v-text-field outlined dense v-model="i.answer"></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="i.question_type == 'MULTIPLE'" dense class="pt-2">
        <v-col
          cols="12"
          v-for="(j, index) in i.option"
          :key="index"
          class="ma-0 pa-0"
        >
          <v-checkbox
            v-model="j.answer"
            class="mt-0"
            style="float: left"
            :label="`${j.choice}`"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row v-if="i.question_type == 'CHECK'" dense>
        <v-radio-group v-model="i.answer">
          <v-radio
            v-for="(j, index) in i.option"
            :key="index"
            :label="`${j.choice}`"
            :value="j.choice"
            class=""
          ></v-radio>
        </v-radio-group>
      </v-row>
    </v-row>
    <v-col cols="12" class="pa-4">
      <v-btn
        block
        outlined
        dark
        x-large
        class="font-weight-bold rounded-lg fn16"
        style="
          background: linear-gradient(268.1deg, #07204b 7.54%, #1a347f 100.72%);
        "
        @click="submit()"
      >
        ตกลง
      </v-btn>
    </v-col>
    <v-dialog v-model="dialogResult" max-width="90%">
      <v-card>
        <v-container>
          <v-row
            align="center"
            justify="center"
            style="text-align: -webkit-center"
            class="my-8"
          >
            <v-col cols="12" class="text-ceter">
              <span class="fn24"> Congratulations 🎉🎉 </span>
            </v-col>
            <v-col cols="12">
              <span class="fn32" style="font-weight: 800"
                >Your Score {{ score }}/{{ point }}</span
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                large
                outlined
                x-large
                class="font-weight-bold rounded-lg text-capitalize btncolor"
                @click="(dialogResult = false), goToHome()"
              >
                Back to Homepage
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import draggable from "vuedraggable";
import { Encode, Decode } from "@/services";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      dialogResult: false,
      style: "preclick",
      image: require("@/assets/BG_Quiz.png"),
      imageCorrect: require("@/assets/BG_correct.png"),
      state: "question",
      currentQuestion: 0,
      selectedAnswer: 0,
      score: 0,

      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],
      items: [],
      search: "",
      count: 0,
      quizData: [],
      stepper: 1,
      clicked: false,
      page: 0,
      point: 0,
      radioGroup: false,
    };
  },
  computed: {
    question() {
      return this.questions[this.currentQuestion];
    },
    scorePercentage() {
      return (this.score * 100) / this.questions.length;
    },
  },
  async created() {
    await this.getOneQuiz();
    await this.getProfile();
    // await this.getUser();
  },
  methods: {
    async getProfile() {
      let _this = this;
      this.$liff.init({ liffId: "1656786311-AEGkzBkO" }).then(() => {
        // alert('in line')
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login();
          console.log("ยังไม่ได้ล้อคอิน");
        } else {
          let that = _this;
          this.$liff.getProfile().then(async function (profile) {
            console.log("ล้อคอินละ", profile);
            that.lineId = profile.userId;
            await that.getUser();
          });
        }
      });
      // let that = this;
      // this.$liff.getProfile().then(async function (profile) {
      //   console.log("ล้อคอินละ", profile);
      //   that.lineId = profile.userId;
      //   await that.getUser();
      // });
    },
    async getUser() {
      console.log("lineIdlineIdlineIdlineId", this.lineId);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findUserByLineId/${this.lineId}`
        // `${process.env.VUE_APP_API}/users/findUserByLineId/U0354ee79b4cd7f2973027952ad0f6fc5`
      );
      console.log("user Data", response.data.data);
      this.userData = response.data.data;
    },
    goToHome() {
      this.$router.push("/");
    },
    async submit() {
      const id = this.$route.query.id;
      const data = {
        userId: this.userData.id,
        ...this.quizData,
      };
      console.log("quizData", data);
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/questionaireAnswerNewSubmit`,
        data
      );
      // console.log("response", response.data.data);
      // this.dialogResult = true;
      // this.getPoint(id);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `ส่งแบบสอบถามสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push("/");
      } else {
        this.$swal.fire({
          icon: "error",
          text: `ส่งแบบสอบถามไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    async getPoint(id) {
      const data = {
        addPoint: this.score,
        type: "QUIZ",
        quizId: id,
        // knowledgeCategory3Id: 1,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/users/${this.userData.id}`,
        // `http://localhost:8081/users/${this.userData.id}`,
        data
      );
      console.log("response", response.data);
      // message: "User was updated successfully"
      // response_status: "SUCCESS"
      if (response.data.response_status == "SUCCESS") {
        this.dialogSuccess = true;
      }
    },
    pluspage(index) {
      if (this.clicked) {
        this.page = index + 1;
        this.clicked = !this.clicked;
      }
    },
    async getOneQuiz() {
      const id = this.$route.query.id;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/questionaireNew/${id}`
        // `http://localhost:8081/quizs/${id}`
      );
      // const response = await this.axios.get(`${process.env.VUE_APP_API}/quizs/${id}`);
      console.log("response", response.data.data);
      this.quizData = response.data.data;
      // this.slides.push(response.data.data);
    },
    handleResponse(answer, point) {
      // console.log("point", point);
      // console.log("score", this.score);
      // ข้อนี้ มีคะแนนเท่าไหร่ 10

      // this.point = point
      this.point += point;
      this.selectedAnswer = answer;
      this.clicked = true;
      if (answer.isAnswer) {
        console.log(answer.isAnswer, "selected.isAnswer");
        this.$swal.fire({
          icon: "success",
          backdrop: `rgba(82,196,26,0.4) url('https://c.tenor.com/pz0zpQXlK5sAAAAi/sparkle-confetti.gif') left top
    no-repeat`,
          text: "correct !",
          showConfirmButton: false,
          timer: 2000,
        });
        this.score += point;
      } else {
        this.$swal.fire({
          icon: "error",
          text: "Incorrect !",
          backdrop: `rgba(245,34,45,0.4)`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      // this.state = "answer";
    },
    handleNext(event) {
      this.currentQuestion++;
      if (this.currentQuestion >= this.questions.length) {
        this.state = "results";
      } else {
        this.state = "question";
      }
    },
    // randomizeOrder() {
    //   this.questions.map((question) => {
    //     question.answers.sort((a, b) => Math.random() - 0.5);
    //     question.answers.map((answer, index) => (answer.id = index));
    //   });
    //   this.questions.sort((a, b) => Math.random() - 0.5);
    // },
    handleRestart(event) {
      this.currentQuestion = 0;
      this.score = 0;
      this.state = "question";
    },
  },
};
</script>

<style scoped>
body {
  height: 100%;
  /* margin: 0; */
}
.preclick {
  background: linear-gradient(268.1deg, #d5e4ff 7.54%, #c1d1ff 100.72%);
}
.background {
  position: absolute;
  /* top: 0; */
  left: 0;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;

  background: #ffffff;
}
.btncolor {
  color: rgb(53, 53, 53);
  background: linear-gradient(268.1deg, #07204b 7.54%, #1a347f 100.72%);
}
</style>
